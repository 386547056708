import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import Dropdown from "react-dropdown";
import ReactGA from "react-ga4";
import {Link, useNavigate} from "react-router-dom";
import Papa from "papaparse";

import PageLoadingSpinner from "./PageLoadingSpinner";
import MHTooltip from "./MHTooltip";
import ExternalLink from "./ExternalLink";

function _getUrl(systemName, vehicleId, highlightKey) {
    const params = {};

    if (systemName) {
        params["systemName"] = encodeURIComponent(systemName);
    }

    if (vehicleId) {
        params["vehicleId"] = encodeURIComponent(vehicleId);
    }

    if (highlightKey) {
        params["highlightKey"] = encodeURIComponent(highlightKey);
    }

    const paramsArray = Object.keys(params).map((paramName) => {
        const paramValue = params[paramName];
        return `${paramName}=${paramValue}`;
    })

    return `${window.location.pathname}?${paramsArray.join("&")}`;
}

const SYSTEM_NAME_OPTIONS = [
    { label: 'ART', value: 'ART' },
    { label: 'DASH', value: 'DASH' },
    { label: 'Fairfax Connector', value: 'Fairfax Connector' },
    { label: 'GRTC', value: 'GRTC' },
    { label: 'MTA Light RailLink', value: 'MTA Light RailLink' },
    { label: 'MTA Local Bus', value: 'MTA Local Bus' },
    { label: 'MTA MARC Train', value: 'MTA MARC Train' },
    { label: 'MTA Metro SubwayLink', value: 'MTA Metro SubwayLink' },
    { label: 'Ride On', value: 'Ride On' },
    { label: 'TheBus', value: 'TheBus' },
    { label: 'VRE', value: 'VRE' },
    { label: 'WMATA Metrobus', value: 'WMATA Metrobus' },
    { label: 'WMATA Metrorail', value: 'WMATA Metrorail' }
];

export default function VehicleHistory() {
    const navigate = useNavigate();

    const params = new URLSearchParams(document.location.search.substring(1));

    const [isLoading, setIsLoading] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [vehicleStopVisits, setVehicleStopVisits] = useState(null);

    const [systemName, setSystemName] = useState(params.get("systemName") || "WMATA Metrobus");
    const [vehicleId, setVehicleId] = useState(params.get("vehicleId") || "");
    const [highlightKey, setHighlightKey] = useState(params.get("highlightKey") || "");

    useEffect(() => {
        // reset scroll
        window.scrollTo(0, 0);

        // on mount
        ReactGA.initialize('G-ZN33QTNMS7');
        ReactGA.pageview(window.location.pathname);

        if (params.has("systemName") && params.has("vehicleId")) {
            search();
        }

        return () => {
            // on unmount
        };
    }, []);

    // if the user changes any filters, reset state
    useEffect(() => {
        setLastUpdated(null);
        setVehicleStopVisits(null);
    }, [systemName, vehicleId]);

    function search() {
        if (!systemName || !vehicleId) {
            return;
        }

        const trimmedVehicleId = vehicleId.trim();
        if (!trimmedVehicleId) {
            return;
        } else if (trimmedVehicleId !== vehicleId) {
            setVehicleId(trimmedVehicleId);
        }

        navigate(_getUrl(systemName, trimmedVehicleId, highlightKey), { replace: true });

        const startMillis = (new Date()).getTime();

        setIsLoading(true);
        setLastUpdated(null);
        setVehicleStopVisits(null);

        async function fetchData() {
            let json;
            try {
                const res = await fetch(`/api/v1/system/${encodeURIComponent(systemName)}/vehicle/${encodeURIComponent(trimmedVehicleId)}/history`);
                const text = await res.text();
                json = JSON.parse(text);
            } catch (e) {
                console.error(e);
            } finally {
                const endDate = new Date();
                const endMillis = endDate.getTime();

                setTimeout(() => {
                    setIsLoading(false);
                    setLastUpdated(endDate.toISOString());
                    if (json) {
                        setVehicleStopVisits(json);
                    } else {
                        setVehicleStopVisits(undefined);
                    }
                }, Math.max(500 - (endMillis - startMillis), 0));  // make sure users always see the loading spinner, but not more than they need to
            }
        }

        setTimeout(fetchData, 0);
    }

    const handleHighlightRef = useCallback(node => {
        if (node) {
            node.scrollIntoView({
                block: "center"
            });
        }
    }, []);

    let lastUpdatedContent;
    if (lastUpdated) {
        lastUpdatedContent = (
            <div
                style={{
                    display: 'inline-block',
                    fontSize: 12,
                    marginBottom: '2em',
                    color: '#666666'
                }}
            >
                <span
                    style={{
                        display: 'inline-block'
                    }}
                >
                    last updated&nbsp;
                </span>
                <span
                    style={{
                        display: 'inline-block'
                    }}
                >
                    <strong>{moment(lastUpdated).toString()}</strong>
                </span>
            </div>
        );
    }

    let pageContent;
    if (isLoading) {
        pageContent = (
            <PageLoadingSpinner />
        );
    } else if (vehicleStopVisits === null) {
        pageContent = (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 230
                }}
            >
                <div>
                    <div>
                        <span
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            Enter a vehicle ID,&nbsp;
                        </span>
                        <span
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            then click the Search button.
                        </span>
                    </div>
                </div>
            </div>
        );
    } else if (!vehicleStopVisits || vehicleStopVisits.length <= 0) {
        pageContent = (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 230
                }}
            >
                <div>
                    <div>
                        <span
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            No data available for&nbsp;
                        </span>
                        <span
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            {systemName} vehicle {vehicleId}&nbsp;
                        </span>
                        <span
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            over the past 30 days!
                        </span>
                    </div>
                    <div
                        style={{
                            marginTop: 6
                        }}
                    >
                        <div
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            Try selecting a different transit system&nbsp;
                        </div>
                        <div
                            style={{
                                display: 'inline-block'
                            }}
                        >
                            or entering a different vehicle ID.
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        const tableRowsByTripInfo = {};
        for (const vehicleStopVisit of vehicleStopVisits) {
            const startOfServiceDayInfo = moment(vehicleStopVisit.startOfServiceDay).calendar(null,{
                lastDay : '[Yesterday\'s]',
                sameDay : '[Today\'s]',
                lastWeek : '[Last] dddd[\'s]',
                sameElse : 'dddd M/D[\'s]'
            });
            const tripInfo = `${startOfServiceDayInfo} Trip ${vehicleStopVisit.tripId} on Route ${vehicleStopVisit.routeId} to ${vehicleStopVisit.tripDestination}`;
            if (!tableRowsByTripInfo[tripInfo]) {
                tableRowsByTripInfo[tripInfo] = [];
            }

            const highlightKeyParam = encodeURIComponent(`${vehicleStopVisit.compositeTripId}-${vehicleStopVisit.scheduledVisitTime}`);
            const stopId = (
                <Link
                    title={`Click to see corresponding row on the transit stop History page for ${systemName} stop ${vehicleStopVisit.vehicleStopId}`}
                    to={`/history/stop?systemName=${encodeURIComponent(systemName)}&stopId=${encodeURIComponent(vehicleStopVisit.vehicleStopId)}&highlightKey=${highlightKeyParam}`}
                >
                    {vehicleStopVisit.vehicleStopId}
                </Link>
            )

            let observedVisitTime;
            let scheduleAdherence;
            if (vehicleStopVisit.isFirstStopInTrip || vehicleStopVisit.isLastStopInTrip) {
                observedVisitTime = (
                    <MHTooltip
                        body={`The ${vehicleStopVisit.isFirstStopInTrip ? 'first' : 'last'} stop of transit trips are not reliably observable due to data limitations.`}
                        noLeftMargin
                        inheritFontSize
                    />
                );
            } else if (vehicleStopVisit.observedVisitTime) {
                observedVisitTime = moment(vehicleStopVisit.observedVisitTime).format('M/D @ h:mm:ss a');

                if (vehicleStopVisit.preVisitDeviationFromScheduledPath > 30 && vehicleStopVisit.postVisitDeviationFromScheduledPath > 30) {
                    const minimumMetersAwayFromStop = Math.min(vehicleStopVisit.preVisitDeviationFromScheduledPath, vehicleStopVisit.postVisitDeviationFromScheduledPath).toFixed(1);
                    observedVisitTime = (
                        <>
                            <em>{observedVisitTime}</em>
                            <MHTooltip
                                icon="⚠"
                                body={`We never observed this vehicle getting any closer than ${minimumMetersAwayFromStop} meters away from this stop, so it may have detoured around it.`}
                                inheritFontSize
                            />
                        </>
                    );
                }

                scheduleAdherence = moment(vehicleStopVisit.observedVisitTime).diff(vehicleStopVisit.scheduledVisitTime, 'minutes');
                if (scheduleAdherence === 0) {
                    scheduleAdherence = "on time";
                } else if (scheduleAdherence > 0) {
                    scheduleAdherence = `${scheduleAdherence} minute${(scheduleAdherence === 1) ? '' : 's'} late`;
                } else if (scheduleAdherence < 0) {
                    const absScheduleAdherence = Math.abs(scheduleAdherence);
                    scheduleAdherence = `${absScheduleAdherence} minute${(absScheduleAdherence === 1) ? '' : 's'} early`;
                }
            }

            const permalinkHighlightKey = `${vehicleStopVisit.compositeTripId}-${vehicleStopVisit.vehicleStopId}-${vehicleStopVisit.scheduledVisitTime}`;
            const shouldHighlight = (permalinkHighlightKey === highlightKey);
            const permalink = (
                <button
                    className="permalink-button"
                    title="Create a permalink to this table row"
                    onClick={() => {
                        if (!shouldHighlight) {
                            setHighlightKey(permalinkHighlightKey);
                            navigate(_getUrl(systemName, vehicleId, permalinkHighlightKey), { replace: true });
                        }
                    }}
                >
                    🔗
                </button>
            );

            tableRowsByTripInfo[tripInfo].push(
                <tr
                    key={permalinkHighlightKey}
                    ref={shouldHighlight ? handleHighlightRef : undefined}
                    className={`table-row${shouldHighlight ? ' highlight' : ''}`}
                >
                    <td>
                        <ExternalLink
                            className="permalink-button"
                            title={`Click to navigate to ${vehicleStopVisit.systemName} transit stop ${vehicleStopVisit.vehicleStopId} on Google Maps`}
                            url={`https://www.google.com/maps/search/?api=1&query=${vehicleStopVisit.stopLatitude},${vehicleStopVisit.stopLongitude}`}
                        >
                            🗺
                        </ExternalLink>
                    </td>
                    <td>{stopId}</td>
                    <td>{decodeURIComponent(JSON.parse(`"${vehicleStopVisit.stopName}"`))}</td>
                    <td>{moment(vehicleStopVisit.scheduledVisitTime).format('M/D @ h:mm:ss a')}</td>
                    <td>{observedVisitTime}</td>
                    <td>{scheduleAdherence}</td>
                    <td>{permalink}</td>
                </tr>
            );
        }

        const tables = Object.keys(tableRowsByTripInfo).map((tripInfo, index) => {
            const tableRows = tableRowsByTripInfo[tripInfo];
            return (
                <div
                    key={tripInfo}
                    className="table-wrapper"
                >
                    <div
                        className="table-title"
                    >
                        {tripInfo}
                    </div>
                    <table
                        className="table"
                    >
                        <thead>
                            <tr className="table-header">
                                <th>🗺</th>
                                <th>Stop ID</th>
                                <th>Stop Name</th>
                                <th>
                                    Scheduled Visit Time
                                    <MHTooltip
                                        body="The time this vehicle was supposed to visit a given stop according to its trip schedule, expressed in your local timezone."
                                        position='top'
                                        inheritFontSize
                                    />
                                </th>
                                <th>
                                    Observed Visit Time
                                    <MHTooltip
                                        body="The time we observed this vehicle visiting a given stop, expressed in your local timezone. A blank value indicates we unexpectedly did not observe this vehicle visiting a given stop, either because of data problems or because the vehicle really didn't visit the stop."
                                        position='top'
                                        inheritFontSize
                                    />
                                </th>
                                <th>
                                    Schedule Adherence
                                    <MHTooltip
                                        body="How early or late we observed this vehicle visiting a given stop relative to when the vehicle was scheduled to do so according to its trip schedule. A blank value indicates we unexpectedly did not observe this vehicle visiting a given stop, either because of data problems or because the vehicle really didn't visit the stop."
                                        position='top'
                                        inheritFontSize
                                    />
                                </th>
                                <th>🔗</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </table>
                </div>
            );
        });

        pageContent = (
            <div>
                {tables}
                <div>
                    <button
                        style={{
                            display: 'block',
                            margin: '8px auto'
                        }}
                        onClick={() => {
                            const csvString = Papa.unparse(vehicleStopVisits);
                            const element = document.createElement('a');
                            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvString));
                            element.setAttribute('download', `ariesVehicleHistory-${systemName}-${vehicleId}-${moment(lastUpdated).unix()}.csv`);
                            element.style.display = 'none';
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                        }}
                    >
                        Download CSV
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div
            className="VehicleHistory container"
            style={{
                position: 'relative',
                height: '100%',
                textAlign: 'center'
            }}
        >
            <h1>Vehicle History</h1>
            <div
                style={{
                    maxWidth: 500,
                    margin: '0 auto',
                    marginBottom: 24,
                    fontSize: 16
                }}
            >
                This tool lists every stop a given vehicle was scheduled to make over the past 30 days in reverse chronological order.
            </div>

            <div
                style={{
                    display: 'inline-block'
                }}
            >
                <div
                    style={{
                        display: 'inline-block',
                        textAlign: 'left',
                        minWidth: 220,
                        maxWidth: 300,
                        margin: '0.5em'
                    }}
                >
                    <div
                        style={{
                            minWidth: '55px',
                            marginRight: '0.5em',
                            marginBottom: '0.5em'
                        }}
                    >
                        Transit system
                    </div>

                    <div
                        className="sortBy-dropdown has-addons"
                    >
                        <Dropdown
                            className="dropdown"
                            options={SYSTEM_NAME_OPTIONS}
                            onChange={data => {
                                setSystemName(data.value);
                            }}
                            value={systemName}
                            placeholder="Select an option"
                            disabled={isLoading}
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: 'inline-block',
                        textAlign: 'left',
                        minWidth: 220,
                        maxWidth: 300,
                        margin: '0.5em'
                    }}
                >
                    <div
                        style={{
                            minWidth: '55px',
                            marginRight: '0.5em',
                            marginBottom: '0.5em'
                        }}
                    >
                        Vehicle ID
                        <MHTooltip
                            body="The physical ID displayed on the inside and/or outside of the vehicle. It should be a series of digits, e.g. 3181."
                            position='top'
                        />
                    </div>

                    <div
                        className="sortBy-dropdown has-addons"
                    >
                        <input
                            style={{
                                width: '220px',
                                fontSize: '16px',
                                cursor: 'inherit',
                                fontFamily: 'Roboto, Helvetica, sans-serif',
                                WebkitFontSmoothing: 'antialiased'
                            }}
                            className="Dropdown-control Dropdown-placeholder"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            type='text'
                            value={vehicleId}
                            onChange={(event) => {
                                setVehicleId(event.target.value.toString());
                            }}
                            disabled={isLoading}
                        />
                    </div>
                </div>
            </div>

            <div
                style={{
                    margin: '0.5em'
                }}
            >
                <button
                    onClick={search}
                    disabled={!systemName || !vehicleId || !vehicleId.trim() || isLoading}
                >
                    Search
                </button>
            </div>

            <hr
                style={{
                    margin: '1em 0.5em'
                }}
            />

            {lastUpdatedContent}

            {pageContent}
        </div>
    );
}